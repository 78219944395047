import React, { useEffect, useMemo, useState } from 'react';
import * as styles from './loginActions.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';

import { sendEvent } from '@src/utils/amplitude';
import { sendDataLayerEvent } from '@src/utils/gtm';
import { isBrowser } from '@src/utils/ssr';
import { connect, useSelector } from 'react-redux';
import { mixPanelService } from '../../../services/mixPanelService';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';

import UrlBuilder from '@src/utils/urlBuilder';

import SteamLogoLarge from '@src/assets/images/loginActions/steam-large.svg';
import FaceItLogoLarge from '@src/assets/images/loginActions/faceit-large.svg';
import SteamLogoMedium from '@src/assets/images/loginActions/steam-medium.svg';
import FaceItLogoMedium from '@src/assets/images/loginActions/faceit-medium.svg';

const eventSources = {
	steam: 'steam',
	faceIt: 'faceit',
};

const events = {
	sources: {
		steam: 'Steam',
		faceit: 'Faceit',
	},
	dataLayer: {
		steam: {
			event: 'funnel_scopegg_entrance',
			event_id: 'd-v37-e37',
			event_cat: 'scope_gg_entrance_landing_event',
			event_name: 'sign_in_through_steam_btn',
		},
		faceit: {
			event: 'funnel_scopegg_entrance',
			event_id: 'd-v36-e36',
			event_cat: 'scope_gg_entrance_landing_event',
			event_name: 'sign_in_through_faceit_btn',
		},
	},
};

const LoginActions = ({ location = '', setLoginPopupOpen, isLoginPopupOpen }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();
	const [steamLink, setSteamLink] = useState('');
	const [faceItLink, setFaceItLink] = useState('');
	const [signUpUrl, setSignUpUrl] = useState('');

	const setUpLinks = () => {
		const appUrl = process.env.GATSBY_APP_URL;
		const backendUrl = process.env.GATSBY_API_URL;

		const steamLink = new UrlBuilder(`${backendUrl}/steamProfiles/auth`)
			.addParam('redirectUrl', `${appUrl}/auth-process`)
			.addParam('backendUrl', backendUrl);

		const faceItLink = new UrlBuilder(`${backendUrl}/faceIt/auth`)
			.addParam('successRedirectUrl', `${appUrl}/auth-process`)
			.addParam('failureRedirectUrl', `${appUrl}/auth-process`);

		const signUpLink = new UrlBuilder(`${appUrl}/signup`);

		setSteamLink(steamLink.build());
		setFaceItLink(faceItLink.build());
		setSignUpUrl(signUpLink.build());
	};

	useEffect(() => {
		if (isBrowser) {
			setUpLinks();
		}
	}, []);

	const handleActionBlockClick = ({ actionSource }) => {
		logActionBlockClickEvents(actionSource);
	};

	const logActionBlockClickEvents = (actionSource) => {
		const eventData = {
			Source: events.sources[actionSource],
			...(location && { location }),
		};

		sendDataLayerEvent(events.dataLayer[actionSource]);

		sendEvent('New Landing / Header Signup Click', eventData);

		mixPanelService.sendEvent(
			{
				event: `Landing / ${location} / Click Sign Up Button`,
				properties: {
					Source: events.sources[actionSource],
				},
			},
			account?.userData?.steamAccountId || null
		);
	};

	return (
		<div className={[styles.wrapper, location === 'Bottom' ? styles.bottomAction : ' '].join(' ')}>
			{/* <a
				href={steamLink}
				className={[styles.action, styles.steamAction].join(' ')}
				onClick={() => handleActionBlockClick({ actionSource: eventSources.steam })}
			>
				<div className={styles.actionContentWrapper}>
					<span className={[styles.actionName, styles.steamName].join(' ')}>{intl.formatMessage({ id: 'buttons.header.steam' })}</span>
					<div className={[styles.actionIcon, styles.actionIconLarge].join(' ')}>
						<img src={SteamLogoLarge} alt="Steam logo" />
					</div>
					<div className={[styles.actionIcon, styles.actionIconSmall].join(' ')}>
						<img src={SteamLogoMedium} alt="Steam logo" />
					</div>
				</div>
			</a>

			<a
				href={faceItLink}
				className={[styles.action, styles.faceItAction].join(' ')}
				onClick={() => handleActionBlockClick({ actionSource: eventSources.faceIt })}
			>
				<div className={styles.actionContentWrapper}>
					<span className={[styles.actionName, styles.faceItName].join(' ')}>{intl.formatMessage({ id: 'buttons.header.faceit' })}</span>
					<div className={[styles.actionIcon, styles.actionIconLarge].join(' ')}>
						<img src={FaceItLogoLarge} alt="Faceit logo" />
					</div>
					<div className={[styles.actionIcon, styles.actionIconSmall].join(' ')}>
						<img src={FaceItLogoMedium} alt="Faceit logo" />
					</div>
				</div>
			</a> */}
			<a
				href={signUpUrl}
				className={[styles.actionButton, styles.signup].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Click Sign Up Button',
							properties: {
								location: 'header',
							},
						},
						account?.userData?.steamAccountId || null
					);
				}}
			>
				<span className={[styles.actionButton, styles.titleText].join(' ')}>
					{intl.formatMessage({ id: 'landing.header.signup' })}
				</span>
			</a>

			<button
				className={[styles.actionButton, styles.signinUsingModal].join(' ')}
				onClick={() => {
					setLoginPopupOpen(true);

					mixPanelService.sendEvent(
						{
							event: 'Landing / Header / Click Login Button',
							properties: {
								location: 'header',
							},
						},
						account?.userData?.steamAccountId || null
					);
				}}
			>
				<span className={[styles.actionButton, styles.titleText].join(' ')}>
					{intl.formatMessage({ id: 'landing.header.signin' })}
				</span>
			</button>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginActions);
