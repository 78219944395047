class UrlBuilder {
	constructor(baseUrl) {
		this.url = new URL(baseUrl);
	}

	addParam(key, value) {
		this.url.searchParams.append(key, value);
		return this;
	}

	build() {
		return this.url.toString();
	}
}

export default UrlBuilder;
