import React, { useEffect, useState } from 'react';
import * as styles from './mobileLoginActions.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';

import { sendEvent } from '@src/utils/amplitude';
import { sendDataLayerEvent } from '@src/utils/gtm';
import { isBrowser } from '@src/utils/ssr';
import { useSelector } from 'react-redux';
import { mixPanelService } from '../../../services/mixPanelService';

import UrlBuilder from '@src/utils/urlBuilder';

const MobileLoginActions = ({ location = '' }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();
	const [steamLink, setSteamLink] = useState('');
	const [faceItLink, setFaceItLink] = useState('');

	const setUpLinks = () => {
		const appUrl = process.env.GATSBY_APP_URL;
		const backendUrl = process.env.GATSBY_API_URL;

		const steamLink = new UrlBuilder(`${backendUrl}/steamProfiles/auth`)
			.addParam('redirectUrl', `${appUrl}/auth-process`)
			.addParam('backendUrl', backendUrl);

		const faceItLink = new UrlBuilder(`${backendUrl}/faceIt/auth`)
			.addParam('successRedirectUrl', `${appUrl}/auth-process`)
			.addParam('failureRedirectUrl', `${appUrl}/auth-process`);

		setSteamLink(steamLink.build());
		setFaceItLink(faceItLink.build());
	};

	useEffect(() => {
		if (isBrowser) {
			setUpLinks();
		}
	}, []);

	const handleSteamClick = () => {
		const eventData = {
			Source: 'Steam',
		};

		if (location) eventData.location = location;
		sendDataLayerEvent({
			event: 'funnel_scopegg_entrance',
			event_id: 'd-v37-e37',
			event_cat: 'scope_gg_entrance_landing_event',
			event_name: 'sign_in_through_steam_btn',
		});
		sendEvent('New Landing / Header Signup Click', eventData);
		mixPanelService.sendEvent(
			{
				event: `Landing / ${location} / Click Sign Up Button`,
				properties: {
					Source: 'Steam',
				},
			},
			account?.userData?.steamAccountId || null
		);
	};

	const handleFaceItClick = () => {
		const eventData = {
			Source: 'Faceit',
		};

		if (location) eventData.location = location;

		sendDataLayerEvent({
			event: 'funnel_scopegg_entrance',
			event_id: 'd-v36-e36',
			event_cat: 'scope_gg_entrance_landing_event',
			event_name: 'sign_in_through_faceit_btn',
		});
		sendEvent('New Landing / Header Signup Click', eventData);
		mixPanelService.sendEvent(
			{
				event: `Landing / ${location} / Click Sign Up Button`,
				properties: {
					Source: 'Faceit',
				},
			},
			account?.userData?.steamAccountId || null
		);
	};

	return (
		<div className={styles.wrapper}>
			<a href={steamLink} className={styles.action} onClick={handleSteamClick}>
				<div className={styles.actionContentWrapper}>
					<span>{'STEAM'}</span>
					<div className={styles.actionIcon}>
						<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0 14.9904L8.59956 18.4865C8.83382 18.3327 9.08348 18.1995 9.34992 18.091C9.95434 17.8424 10.5918 17.7311 11.227 17.76L15.1484 12.0725C15.147 12.0472 15.147 12.0203 15.147 11.9934C15.147 8.63682 17.8775 5.90625 21.2338 5.90625C24.5915 5.90625 27.3206 8.63682 27.3206 11.9934C27.3206 15.3497 24.5915 18.0827 21.2338 18.0827C21.1884 18.0827 21.1416 18.0814 21.0963 18.08L15.4854 22.0834C15.5019 22.7014 15.3877 23.3208 15.1429 23.9114C14.202 26.1706 11.5941 27.246 9.33231 26.3025C7.94989 25.727 7.01287 24.5308 6.70723 23.167L0.698856 20.7885C2.73781 27.2858 8.81499 32 15.9835 32C24.82 32 31.9983 24.8366 31.9983 16C31.9983 7.16344 24.82 0 15.9835 0C7.48599 0 0.521951 6.62423 0 14.9904ZM9.72242 25.3658C11.4688 26.0923 13.4779 25.2642 14.2061 23.5186C14.5583 22.6754 14.5597 21.7414 14.2116 20.8954C13.8636 20.0494 13.207 19.3875 12.3606 19.0359C11.5211 18.687 10.6226 18.698 9.83219 18.9974L11.8805 19.8448C13.1677 20.3804 13.777 21.8582 13.24 23.1451C12.7046 24.4305 11.227 25.0403 9.93907 24.5047L7.9576 23.6848C8.3085 24.4168 8.91676 25.0307 9.72242 25.3658ZM17.1787 11.9934C17.1787 14.2304 18.9985 16.0501 21.2338 16.0501C23.4704 16.0501 25.2903 14.2304 25.2903 11.9934C25.2903 9.7582 23.4704 7.93791 21.2338 7.93791C18.9985 7.93791 17.1787 9.7582 17.1787 11.9934ZM21.2407 8.93952C19.5598 8.93952 18.1952 10.3044 18.1952 11.9872C18.1952 13.6702 19.5598 15.0338 21.2407 15.0338C22.923 15.0338 24.2875 13.6702 24.2875 11.9872C24.2875 10.3044 22.923 8.93952 21.2407 8.93952Z"
								fill="#86A0BA"
							/>
						</svg>
					</div>
				</div>
			</a>

			<a href={faceItLink} className={styles.action} onClick={handleFaceItClick}>
				<div className={styles.actionContentWrapper}>
					<span>{'FACEIT'}</span>
					<div className={styles.actionIcon}>
						<svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M27.75 0L37 16L27.75 32H9.25L0 16L9.25 0H27.75ZM25.5149 3.52979L32.5372 15.997L25.5149 28.4642H11.4706L4.44835 15.997L11.4706 3.52979H25.5149ZM24.838 10.1045V20.6421C24.838 20.7127 24.7668 20.7611 24.7011 20.735L11.5532 15.5148C11.4512 15.4743 11.4794 15.3229 11.5892 15.3219L21.2376 15.2357L24.6545 10.0495C24.7091 9.96666 24.838 10.0053 24.838 10.1045Z"
								fill="#F05B1B"
							/>
						</svg>
					</div>
				</div>
			</a>
		</div>
	);
};

export default MobileLoginActions;
