import React from 'react';
import * as styles from './menuList.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import MenuItem from '@components/molecules/MenuItem';
import { links } from '@src/constants';
import { sendEvent } from '@utils/amplitude';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const MenuList = () => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	return (
		<div className={styles.container}>
			<MenuItem withSubmenu={true} title={intl.formatMessage({ id: 'landing.menu.tools' })}>
				<a
					href={links.csLineups[intl.locale]}
					target="_blank"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'csLineups',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / csLineups',
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.csLineups' })}
				</a>
				<a
					href={links.strategy[intl.locale]}
					target="_blank"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'board',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / board',
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.board' })}
				</a>
				<a
					href={links.comparing[intl.locale]}
					target="_blank"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'comparing',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / comparing',
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.comparing' })}
				</a>
				<a
					href={links.faceitStats[intl.locale]}
					target="_blank"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'faceit',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / faceit',
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.faceit' })}
				</a>
				<a
					href={links.prematch[intl.locale]}
					target="_blank"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'prematch',
						});

						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / prematch',
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.prematch' })}
				</a>
			</MenuItem>
			<MenuItem withSubmenu={true} title={intl.formatMessage({ id: 'landing.menu.more' })}>
				<a
					href={links.faq[intl.locale]}
					className={styles.submenuItem}
					target="_blank"
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'faq',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / Click More',
								properties: {
									Path: 'faq',
								},
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.faq' })}
				</a>
				<a
					href={links.blog[intl.locale]}
					className={styles.submenuItem}
					target="_blank"
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'blog',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / Click More',
								properties: {
									Path: 'blog',
								},
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.blog' })}
				</a>
				{/* <AnchorLink
					href="#about"
					className={styles.submenuItem}
					onClick={() => {
						sendEvent('New Landing / Click Header', {
							Page: 'about',
						});
						mixPanelService.sendEvent(
							{
								event: 'Landing / Header / Click More',
								properties: {
									Path: 'about',
								},
							},
							account?.userData?.steamAccountId || null
						);
					}}
				>
					{intl.formatMessage({ id: 'landing.menu.about' })}
				</AnchorLink> */}
			</MenuItem>
		</div>
	);
};

export default MenuList;
